<template>
<div>

    <v-row>
        <v-col>
            <s-crud ref="crudarticles" add :edit="item!==null" :remove="item!==null" no-full @save="save($event)" searchInput title="Articulos" :config="config" :filter="filter" @rowSelected="rowSelected($event)">
                <template scope="props">
                    <!-- <v-col> -->
                    <!-- <v-card outlined> -->
                    <v-row justify="center">
                        <v-col cols="12" class="s-col-text">
                            <s-text label="Descripcion" autofocus v-model="props.item.ArtName" ref="ArtName"></s-text>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" class="s-col-text">
                            <s-select-definition :def="1129" label="Moneda" v-model="props.item.TypeCurrency"></s-select-definition>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" class="s-col-text">
                            <s-text label="Precio" v-model="props.item.ArtPrice" decimal ref="ArtPrice"></s-text>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" class="s-col-text">
                            <s-select-definition :def="1160" label="Un. Medida" v-model="props.item.TypeUnitOfMeasuarement"></s-select-definition>
                        </v-col>
                        <v-col cols="12" lg="3" md="3" class="s-col-text">
                            <s-select-definition :def="1182" label="Almacen" v-model="props.item.TypeWhs"></s-select-definition>
                        </v-col>
                    </v-row>
                    <!-- </v-card> -->

                </template>

                <template v-slot:SecStatus="{ row }">
                    <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
                </template>
                <template v-slot:Add="{ row }">
                    <v-btn icon text class="capitalize save" color="info" small elevation="0" @click="show(row)">
                        <v-icon style="font-size:14px; border-radius: 10px" class="mr-1">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </template>
            </s-crud>
        </v-col>
    </v-row>

    <div v-if="showDialog">
        <v-dialog class="elevation-0" v-if="showDialog" v-model="showDialog" max-width="50%" persistent transition="dialog-bottom-transition">

            <s-toolbar :label="'Articulo Nro. ' + item.ArtCode" close @close="showDialog=false"></s-toolbar>
            <v-container>
                <v-card outlined>

                    <!-- <v-row style="margin:auto"> -->

                        <v-col cols="12">
                            <s-text label="Cantidad" ref="ArtStock" decimal v-model="ArtStock"></s-text>
                        </v-col>

                        <v-col cols="12">
                            <v-btn x-small elevation="0" color="info" @click="insertStock()">Grabar</v-btn>
                        </v-col>

                    <!-- </v-row> -->
                </v-card>
            </v-container>
        </v-dialog>
    </div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</div>
</template>

<script>
import _sArticle from '@/services/Logistics/ArticleService'
import SText from '../../../components/Utils/SText.vue';

export default {
    components: {
        SText
    },
    props: {},

    data: () => ({
        processing: false,
        messageProcessing: "",
        showDialog: false,
        filter: {},
        config: {
            model: {
                ArtID: "ID",
                ArtCode: "string",
                ArtName: "string",
                ArtStock: "int",
                ArtAvgPrice: "decimal",
                SecStatus: "",
                Add: "",
                ArtPrice: "decimal",
                LastPurCurrency:"string"
            },
            service: _sArticle,
            headers: [{
                    text: "ID",
                    value: "ArtID"
                },
                {
                    text: "Codigo",
                    value: "ArtCode"
                },
                {
                    text: "Descripcion",
                    value: "ArtName"
                },
                {
                    text: "UM",
                    value: "UnitOfMeasuarement"
                },
                
                {
                    text: "Moneda",
                    value: "LastPurCurrency"
                },
                {
                    text: "Precio",
                    value: "ArtPrice"
                },
                {
                    text: "Prc. Promedio",
                    value: "ArtAvgPrice"
                },
                {
                    text: "Stock",
                    value: "ArtStock"
                },
                {
                    text: "Ingreso",
                    value: "Add"
                },
                {
                    text: "Estado",
                    value: "SecStatus"
                },
            ],
        },

        item: null,
        ArtStock: 0

    }),
    methods: {

        refresh(){
            this.$refs.crudarticles.refresh();
        },

        rowSelected(item) {

            if (item.length > 0 && item !== null) {
                this.item = item[0]
                console.log("item", item)
            }

        },

        show(item) {
            console.log("show", this.item)
            this.item = item
            this.showDialog = true
        },

        insertStock(){
            if(this.ArtStock <= 0){
                this.$refs.ArtStock.error("Ingrese una cantidad correcta")
                return
            }

            this.item.ArtStock = parseFloat(this.ArtStock)
            this.item.UsrCreateID = this.$fun.getUserID()

            this.$fun.alert("Seguro de grabar ?","question").then(val => {
                if(val.value){
                    _sArticle.insertStock(this.item,this.$fun.getUserID()).then(resp => {
                        if(resp.status == 200){
                            this.$fun.alert("grabado correctamente", "success")
                            this.ArtStock = 0
                            this.refresh();
                            this.showDialog = false
                        }
                    })
                }
            })

            console.log("save", this.item)
        },

        save(item) {

            if (item.ArtName == null || item.ArtName.length == 0) {
                this.$refs.ArtName.error("Ingrese descripcion")
                return
            }

            if (item.TypeUnitOfMeasuarement == null) {
                this.$fun.alert("Seleccione Unidad de medida", "warning")
                return
            }

            if (item.TypeWhs == null) {
                this.$fun.alert("Seleccione Almacen", "warning")
                return
            }

            if (item.ArtName.length > 100) {
                this.$refs.ArtName.error("Maximo 100 Caracteres")
                return
            }



            console.log("save", item)

            item.save()
        }
    },
};
</script>
